@import "../../../scss/variables.scss";
.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
}
.sp {
    height: 32px;
    clear: both;
    margin: 20px auto;
  }

.sp-bars {
    position: relative;
    width: 10px;
    border: 1px $secondary solid;
    background-color: rgba(198,67,57, 0.5);
    -webkit-animation: spBars 1s infinite linear;
    animation: spBars 1s infinite linear;
}

.sp-bars:after,
.sp-bars:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border: inherit;
    background-color: inherit;
    top: -1px;
}

.sp-bars:before {
    left: -20px;
    -webkit-animation: spBarsBefore 1s infinite linear;
    animation: spBarsBefore 1s infinite linear;
}

.sp-bars:after {
    right: -20px;
    -webkit-animation: spBarsAfter 1s infinite linear;
    animation: spBarsAfter 1s infinite linear;
}

@keyframes spBarsBefore {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1.25);
    }
    50% {
        transform: scale(1, 0.75);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes spBars {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1.25);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes spBarsAfter {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.75);
    }
    75% {
        transform: scale(1, 1.25);
    }
    100% {
        transform: scale(1, 1);
    }
}
