@import "../../../src/scss/variables";

.agent-remindertab {
    display: inline-flex;
    #agentReminderTabModal {
        .ant-modal-header {
            display: none;
        }
        .ant-modal-body {
            padding: 0;
        }
        .ant-tabs-nav-list {
            width: 100%;
            border-radius: 20px 20px 0 0;
            overflow: hidden;
        }
        .ant-tabs-tab {
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: center;
            margin: 0px;
        }
        .ant-tabs-ink-bar {
            background: transparent !important;
        }
        .ant-tabs-nav {
            &:before {
                border-bottom: 0px;
            }
        }
        .ant-tabs-tab-active {
            background-color: $secondary;
            transition: all 0.5s ease;
            .ant-tabs-tab-btn {
                color: $white;
            }
        }
        .textarea-notes {
            padding: 20px 20px 0 20px;
            textarea.ant-input {
                border-radius: 20px;
            }
        }
        .conversation-status {
            padding: 0 20px 20px 20px;
            .ant-select {
                width: 100%;
                .ant-select-selector {
                    border-radius: 20px;
                }
            }
        }
        .reminder-box {
            border-radius: 20px;
            background-color: $primary-blue;
            .notes-content {
                margin: 0px 2px;
                background-color: $white;
            }
        }
        .save-notes {
            background-color: $primary-blue;
            border-radius: 0 0 20px 20px;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &__btn {
                background-color: $dark-blue;
                color: $white;
                border-radius: 20px;
            }
             &__cancel-btn {
                background-color: #f5f5f5;
                color: $dark-blue;
                border-radius: 20px;
                margin: 0 2px;
                width: 80px;
            }
        }
        .notes-header {
            padding: 15px;
            background-color: $primary-blue;
            border-radius: 20px 20px 0 0;
            h3 {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .reminder-checkbox {
            padding: 20px 20px 0 20px;
            input {
                cursor: pointer;
            }
            label {
                padding-left: 10px;
            }
        }
        .addreminder-content {
            padding: 0 20px 20px 20px;
            
            &__firstbox {
                display: flex;
                justify-content: center;
                align-items: center;
                &--input {
                    flex-grow: 1;
                    flex-basis: auto;
                    margin-right: 5px;
                    .ant-input {
                        border-radius: 20px;
                        padding: 7px 0 7px 10px;
                    }
                }
                .reminder-time {
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    flex-basis: auto;
                    margin-left: 5px;
                 
                    .ant-picker {
                        display: flex;
                        border-radius: 20px;
                    }
                    .select-editable {
                        position:relative;
                        background-color:white;
                        border:solid #d9d9d9 1px;
                        width:124px;
                        height:40px;
                        border-radius: 20px;
                        }
                        .select-editable select {
                        position:absolute;
                        top:0px;
                        left:0px;
                        font-size:14px;
                        border:none;
                        padding:8px;
                        width:95%;
                        margin:0;
                        height: 4vh;
                          border-radius: 20px;
                        }

                        .select-editable input {
                        position:absolute;
                        top:0px;
                        left:0px;
                        width:100px;
                        padding:8px;
                        font-size:14px;
                        border:none;
                          border-radius: 20px;
                        }
                        .select-editable select:focus, .select-editable input:focus {
                        outline:none;
                        }
                }
            }
        }
    }
}