@import "../../../src/scss/variables";
.top-header {
  &.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme-black;
    height: 60px;
    padding-left: 60px;
    position: fixed;
    width: 100%;
    z-index: 90;
    @media (max-width: 575px) {
      // display: block;
      // justify-content: space-between;
      // padding-right: 65px;
    }
    .dropdown-item:active {
      background: $secondary;
    }
    .dropdown-item.active {
      background: none;
      color: #212529;
    }
  }
  &__left {
    //margin-left: 20px;
    @media only screen and (max-width: 675px) {
      .logo {
        img {
          width: 80px !important;
        }
      }
    }
    .logo {
      img {
        width: 160px;
      }
    }
  }
  &__right {
    // margin-right: 2vh;
    display: flex;
    .navbar-nav {
      align-items: center;
    }
    .logged-agentname {
      color: $white;
      span {
        text-transform: capitalize;
      }
    }
    .dropdown {
      margin-left: 15px;
      width: 35px;
      height: 35px;
      @media (max-width: 575px) {
        margin-right: 15px;
      }
      &-toggle {
        border-radius: 50%;
        border: 0px;
        padding: 0px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        position: relative;
        @media (max-width: 575px) {
          margin-left: auto;
          margin-bottom: 15px;
          width: 40px;
          height: 40px;
        }
        &:after {
          position: absolute;
          right: -22px;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 26px;
          display: none;
        }
        &:hover,
        &:focus,
        &:active {
          background: transparent !important;
          box-shadow: none;
        }
      }
      .dropdown-item {
        padding: 4px 16px;
        &.log-out {
          color: $colorred;
          &:active {
            color: $white;
          }
        }
        .user-role {
          text-transform: capitalize;
          color: $black;
          font-style: italic;
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
        }
        .user-company {
          color: $mix-yellow;
          line-height: 16px;
          font-weight: 500;
          font-size: 18px;
        }
      }
      &.available-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 15px 4px 16px;
        @media (max-width: 575px) {
          justify-content: flex-start;
        }
        .available-status__text {
          margin-bottom: 0px;
          margin-right: 10px;
        }
        .available-status__switch {
          @media (max-width: 575px) {
            margin-left: 10px;
          }
        }
        .btn-primary {
          background: $secondary;
          border-color: $secondary;
        }
      }
      &:nth-child(1) {
        .dropdown-menu {
          @media (max-width: 575px) {
            margin-bottom: 15px !important;
          }
        }
      }
    }
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      background: transparent !important;
      box-shadow: none;
    }
    .img_wrap {
      overflow: hidden;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      .thumbnail-image {
        width: 100%;
      }
    }

    .navbar-toggler {
      position: absolute;
      right: 20px;
      top: 10px;
    }
    .navbar-collapse {
      @media (max-width: 575px) {
        background-color: $theme-red;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%),
          0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        margin-left: 40px;
        padding: 20px 0;
      }
    }
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .rating-box {
    display: flex;
    align-items: center;
    line-height: 16px;
    .rating-value {
      margin-right: 10px;
      margin-top: 2px;
      color: $orange-dark;
    }
    .ant-rate {
      font-size: 14px;
      .ant-rate-star-zero {
        svg {
          path {
            fill: #b1b0b0;
          }
        }
      }
      .ant-rate-star > div:hover,
      .ant-rate-star > div:focus {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  .nav-menu-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px auto;
  }
  .dropdown-menu-right {
    width: 250px;
  }
  .nav-menu-options-ontoggle {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // margin: 3px auto;
  }
}
.mobileuser {
  .ant-card-body {
    padding:7px;
  }
}
.toggleDrawer {
  .ant-drawer-body {
    // background-color: #c64339 ;
  }
  
  .logged-agentname {
    // background-color: #c64339 ;
    color: rgb(8, 8, 8);
      span {
        text-transform: capitalize;
        font-weight: bold;
      }

  }
  .user-company {
    color: $mix-yellow;
    line-height: 16px;
    font-weight: 500;
    font-size: 18px;
  }

}

.status-switch {
  display: flex;
  color: white;
  padding-top: 2%;
  @media only screen and (max-width: 675px) {
    margin-right: 48px !important;
  }
  .ant-switch {
    background-color: azure;
  }
  .ant-switch-checked {
    background-color: #50c639 !important;
  }
}

.status-item {
  margin: 10px;
}
