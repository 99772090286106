.resizable-window {
  position: absolute;
  top: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  transition: all 0.2s ease-in-out;
}

.resizable-window.maximized {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.window-buttons {
  display: flex;
  gap: 8px;
}

.window-actions {
  display: flex;
}

.window-action {
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
}

.window-content {
  padding: 10px;
}

.minimize-button,
.maximize-button,
.close-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
}

.expand-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
}

.window-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px;
  background-color: #c64339;
  cursor: move;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.resize-handle {
  position: absolute !important;
  //   width: 16px;
  //   height: 16px;
  bottom: 0;
  right: 0;
  //   background-color: #34495e; /* Resize handle color */
  cursor: nwse-resize;
  border-bottom-right-radius: 6px;
}

