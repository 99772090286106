$theme_color: #e9ecef;
$black: #000;
//$secondary: #7cc1e4;
$secondary: #c64339;
$white: #fff;
$theme_bg: #f4f8fc;
$gray_text: #6c7a85;
$gray200: #ebebeb;
$colorred: #ff6767;
//$primary-blue: #32bae2;
$primary-blue: #c64339;
$bg-blue: #e9f7fc;
//$dark-blue: #000046;
$dark-blue: #2a2e30;
$light-blue: #1cb3df;
//$theme-blue: #39BFE4;
$theme-blue: #c64339;
$table-header-blue: #070a4f;
$accodion-blue: #F6FCFE;
$hover-color: #f8f9fa;
$input-box-bg: #f5f5f5;
$border-color: #8d8dad;
$light-ash: #707097;
$notification-yellow: #C1C128;
$text-blue: #0d91b7;
$graph-border: #BECADC;
$violet-dark: #A2A2D2;
$violet-light: #BDCAE6;
$blue-dark: #2AD2E1;
$blue-light: #83E2EE;
$orange-dark: #FFA44F;
$orange-light: #EBCBA7;
$green-dark: #4BE743;
$green-light: #92EDA0;
$select-border: #717198;
$red-color: #b20004;
$green-color: #00a300;
$theme-red: #c64339;
$theme-black:  #2a2e30;
$charcol-grey: #404c53;
$border-ash: #4c4754;
$fb-color: #3b5998;
$twitter-color:#00acee;
$skype-color:#00aff0;
$green: #008000;
$red: #ff0000;
$yellow: #d5d506;
$mix-yellow: #c78d0b;
$info-text: #646262;
$standard-blue : #0c66e4;

$fontxs: 12px;
$fontsm: 14px;
$fontmd: 15px;
$fontlg: 18px;
$fontxl: 20px;