@import "../../../src/scss/variables";
.sidebar-block {
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    width: auto;
    .hamburger-btn {
        height: 60px;
        margin-left: 10px;
        background: transparent;
        border: 0px;
        &:hover,
        &:focus,
        &:active {
            background: transparent;
        }
        svg {
            transform: scale(2);
        }
    }
    &__menu {
        height: 100%;
        .ant-menu {
            height: 100%;
            background-color: $theme-red ;
            &-item {
                // min-height: 50px;
                color: #ffffff !important;
                padding-left: 16px !important;
                svg {
                    path {
                        color: $white;
                    }
                }
                span {
                    a {
                        color: $white !important;
                    }
                }
                &.ant-menu-item-selected {
                    background-color: $theme-black !important;
                    color:#fff; // #32bae2 ;
                    .sidebar-block__menu--icon {
                        color: #fff !important; //#32bae2 !important;
                        span {
                            color: #fff !important ; //#32bae2 !important;
                        }
                    }
                    span {
                        a {
                            color: #fff ; //#32bae2 !important;
                        }
                    }
                }
            }
            &.ant-menu-inline-collapsed {
                .ant-menu-item {
                    padding-left: 16px;
                }
            }
        }
        .ant-menu-inline-collapsed {
            width: 70px;
        }
        &--icon {
            font-size: 30px !important;
            color: #ffffff !important;
        }
        .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span, .ant-menu-dark .ant-menu-item-selected .anticon + span {
            color: #32bae2;
        }
    }
}