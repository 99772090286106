@import "../../../src/scss/variables";

.err_wrap {
    display: flex;
    justify-content: center;
    .err_msg {
        font-weight: 700;
        color: #c64339;
    }
} 
.btn_wrap {
    text-align: center;
}
