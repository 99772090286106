@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  background-color: $theme_bg !important;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top_nav {
  background-color: $theme_color !important;
  padding: 0 15px !important;
  height: 9vh;
  .navbar-brand{
    width: 130px;
  }
  a.nav-link {
    color: #000046 !important;
    padding: 12px 25px !important;
    font-size: $fontmd;
    height: 80px;
    display: flex;
    align-items: center;
    &.active {
      background-color: #0092ff;
      border-bottom: 4px solid $secondary;
    }
  }
  .btn_user {
    border: none !important;
    background-color: transparent;
    color: black;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
    .user_thumb {
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }
  }
}
.dash_bar {
  background-color: #0050ac !important;
  padding: 0px;
  border-radius: 5px;
  color: #fff;
  align-items: center;
  h2 {
    font-size: $fontxl;
    margin: 0;
  }
  .flexone {
    flex: 1;
  }
  h1 {
    font-size: 26px;
    font-weight: bolder;
    margin: 0;
    &.yellow {
      color: #ffe22a;
    }
  }
  .border_right {
    border-right: 1px dotted #2ea4ff;
  }
}
.chart_block {
  h3 {
    font-size: $fontxl;
    font-weight: bolder;
    margin-bottom: 15px;
  }
  h4 {
    font-size: $fontlg;
  }
  .card_home {
    box-shadow: 0 2px 6px #cfcfcf;
    border: none !important;
    margin-bottom: 15px;
  }
}

.chat-page {
  height: 100%;
  min-height: 91vh;
  .left-box {
    background: $theme_bg;
    .user-list {
      padding-left: 0 !important;
      li {
        list-style: none;
        border-bottom: 1px solid #e0e5eb;
        width: 100%;
        padding: 20px 15px;
        display: flex;
        align-items: center;
        transition: all 0.8s;
        cursor: pointer;
        &:hover {
          background-color: #cde9ff;
          transition: all 0.8s;
        }
        &.active {
          background-color:  #a5e2e2;
          transition: all 0.8s;
        }
        .text-sec {
          max-width: 190px;
        }
        h3 {
          color: $gray_text;
          font-size: 18px;
          margin: 0px;
        }
        .user_thumb_list {
          width: 35px;
          height: 35px;
          border-radius: 50px;
          margin: 0px 15px;
        }
        h2 {
          font-size: 14px;
          color:#0c0c0c;
          font-weight: bold;
          margin: 0px;
        }
        p {
          font-size: 13px;
          color: #0d0e0e;
          margin: 0px;
          span {
            color:  #0e0e0e;
          }
        }
        h4 {
          color:#0c0c0c;
          font-size: 24px;
          margin: 0px;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  .left-mid {
    background: $white;
    position: relative;
    padding: 0;
    padding-bottom: 100px;

    .chat-box {
      height: 76vh;
      overflow-y: auto;
      &.chat_bxmodel{
        height: 84vh !important;
      }

      .user_thumb {
        width: 35px;
        height: 35px;
        border-radius: 50px;
      }
      .chat-bg {
        color:black;
        background: rgba(0,123,255,.5);
        border-radius: 0px 20px 20px 20px;
        max-width: 400px;
        p {
          padding: 10px;
          margin: 0px;
          font-size: 14px;
        }
      }
      .chat-bg-me {
        color: $white;
        background: #edf1f5;
        border-radius: 20px 0px 20px 20px;
        max-width: 400px;
        p {
          padding: 15px;

          margin: 0px;
          font-size: 15px;
          color: $secondary;
        }
      }
      .date-time {
        color: $gray_text;
        margin-top: 15px;
        font-size: 14px;
      }
    }

    .message-box {
      position: absolute;
      bottom: 78px;
      border: 1px solid #dcdcdc;
      padding: 5px;
      width: 100%;
      background: $white;
    }
    .input-custom {
      border: none;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
  .right-mid {
    .dropdown-item {
      line-height: 35px;
      border-bottom: 1px solid #ebebeb;
      font-size: 18px;
    }
    .dropdown-toggle::after {
      content: "";
      display: none;
    }
    background: $secondary;
    .user_card {
      .user_thumb {
        width: 62px;
        height: 62px;
        border-radius: 50px;
        margin-right: 15px;
      }
      h2 {
        font-size: 18px;
        color: $secondary;
        font-weight: bold;
      }
      p {
        font-size: 15px;
        color: $secondary;
        margin: 0px;
        span {
          color: $gray_text;
        }
      }
      h3 {
        font-size: 16px;
        color: $secondary;
        font-weight: bold;
      }
    }
    .visiting-list {
      li {
        line-height: 44px;
        list-style: none;
        a {
          font-size: 16px;
        }
      }
    }
  }
}
.flex-one {
  flex: 1;
}
.user_option {
  a {
    border-top: 1px solid $gray200;
    padding: 12px 20px;
  }
  .color_red {
    color: $colorred;
  }
}

.video_model {
  position: relative;
  &.model_popup{
    .modal-dialog {
      max-width: 800px !important;
    }
    .modal-title{
      color: #fff;
      font-size: 18px;
    }
  }
  .modal-dialog {
    position: fixed;
    top: 9%;
    right: 1%;
    transform: translate(0%, 0%) !important;
    max-width: 435px;
    width: 100%;
    margin: 0;
    border-radius: 0 !important;
    height: 89vh !important;
    display: flex;
    .modal-content {
      border-radius: 0 !important;
      background-color: $secondary;
      .modal-header {
        border-radius: 0 !important;
        border-bottom: none !important;
        .close {
          color: $white !important;
          opacity: 1;
          text-shadow: none !important;
        }
        .sr-only {
          position: relative !important;
          clip: inherit !important;
          overflow: inherit !important;
          font-size: 16px;
        }
      }
      .modal-body {
        padding: 0 !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}
.btn-outline-secondary:hover {
  background-color: inherit !important;
}

.custom-tab {
  background: $white;
  font-size: 14px;
 

  a:first-child {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
    //  background: #22c949;
      display: inline-block;
      margin-right: 10px;
    
    }
  }
  a:nth-child(2) {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
    //  background: #d5d5d5;
      display: inline-block;
      margin-right: 10px;
    }
  }
  a:nth-child(3) {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
    //  background: #ee8f13;
      display: inline-block;
      margin-right: 10px;
    }
  }
  a:nth-child(4) {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
    //  background:  #f6fa1b;
      display: inline-block;
      margin-right: 10px;
    }
  }
  a:nth-child(5) {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
    //  background: #8f0707;
      display: inline-block;
      margin-right: 10px;
    }
  }
  a {
     width: 10px;
    text-align: center;
    border-bottom: 3px solid #ffffff !important;
    color:black;
  }
  .nav-link {
    border-left: none !important;
    border-right: none !important;
  }
}

.custom-tab .nav-item.show .nav-link,
.custom-tab .nav-link.active {
  color:  #250dec !important;
  border-bottom: 3px solid  #194e83 !important;
 
}

.secondary-tab .nav-link {
  width: 50%;
  text-align: center;
  border: 0px solid transparent;
}



.cutom-card {
  height: 220px;
  border: none !important;
  border-radius: 0px !important;
  background-color: #3c53e9 !important;
  img {
    max-width: 70px;
  }
}
.cutom-card2 {
  background-color: #ff6767 !important;
}
.cutom-card3 {
  background-color: #111913 !important;
}
.custom-table {
  th {
    border: none !important;
    color: $gray_text;
    font-weight: normal;
  }
  td {
    font-size: 14px;
    padding: 15px 20px !important;
  }
}
.img_row {
  height: 100vh !important;
  overflow: hidden;
}
.form_login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px !important;
  padding: 20px;
  margin: 30px auto;
  img {
    max-width: 200px;
    margin-bottom: 40px;
  }
}
.row_call {
  justify-content: flex-start;
  height: 96%;
  align-items: flex-end;
  padding: 5px;
  padding-left: 13px;
  position: relative;
  top: 417px;
}
.bg_red {
  background-color: #ff6767 !important;
  border: none !important;
}
.bg_blue {
  background-color: #3c53e9 !important;
  border: none !important;
}
.bg_gray {
  background-color: #44446b !important;
  border: none !important;
}
.bg_yellow {
  background-color: #d9cb06 !important;
  border: none !important;
}
.wrapper_video {
  position: relative;
  .wp_btnvideo {
    text-align: center;
    position: absolute;
    top: 391px;
    z-index: 1004;
    width: 100%;
    .btn_end {
      background-color: #0062cc !important;
      height: 55px;
      width: 55px;
      border-radius: 30px !important;
    }
    .btn_mute {
    background-color:  #28a745 !important;
      height: 55px;
      width: 55px;
      border-radius: 30px !important;
    }
    .btn_videoOff {
      background-color:   #adb5bd !important;
        height: 55px;
        width: 55px;
        border-radius: 30px !important;
      }
  }
  .wp_record {
    text-align: center;
    position: absolute;
    top: 333px;
    z-index: 1004;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    padding-right: 95px;
    p {
      margin-left: 10px;
      font-weight: bold;
      margin-bottom: 0;
      span {
        color: #ff6767;
      }
    }
    .btn_record {
      background-color: #fff !important;
      height: 50px;
      width: 50px;
      border-radius: 25px !important;
      img {
        margin-top: -5px;
      }
    }
  }
}
.option_wp {
 // background-color: #f2f2f2;
  padding: 15px;
  width: 51%;
  text-align: right;
  .user_add {
    width: 26px;
  }
  .more{
    width: 4px;
  }
  .dropdown-toggle{
    display: inline-flex;
    align-items: center;
    padding: 8px;
    &::after{
      display: none !important;
    }
  }
}
.option_pstn {
  position: relative;
  bottom: 106px;
  left: 72%;
 // background-color: #f2f2f2;
  padding-top: 10px;
  padding-bottom: 14px;
  max-height: 63px;
}

.option_browse {
  position: relative;
  bottom: 54px;
  right: -172px;
//  background-color: #f2f2f2;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}




.accordion:after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

#accordionbutton.active:after {
  content: "\2212";
}



.collapseContent {
 // padding: 0 18px;
  background-color: white;
  max-height: 0;
  display: none;
  transition: max-height 0.2s ease-out;
}

.tabImage{
  width:30px;
}

.div_background{
  background-color: #80808014;
  height: 60px;
}

.option_image{
  position: relative;
  left: -7px;
  width: 20px;
}

.text-black{
  color: black;
}

.modal-backdrop {
background-color: transparent  !important;;
}

.smallSize{
  width: 20px!important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.notes-box {
  position: absolute;
  bottom: 0px;
  border: 1px solid #dcdcdc;
  padding: 10px;
  width: 100%;
  background: $white;
}
.messageArea{
  padding: 20px;
}
/* login page*/
.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-image {
  width: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
  &__left {
    width: 100%;
    position: absolute;
    top: -128px;
    height:646px;
  }
}
.login-image h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}
.loginpage-wrapper {
  display: flex;
  box-shadow: 0 0 10px #c4bdbd;
  border-radius: 8px;
}
.login-box {
  padding: 50px 30px;
  max-width: 350px;
  min-width: 350px;
  color: white;
  background: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.login .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 40px auto;
  flex-direction: column;
}
.login .logo img {
  width: 200px;
}
.login .logo p {
  font-size: 12px;
  font-weight: 600;
  color: #404074;
  margin-top: 0px;
  margin-bottom: 16px;
  font-style: italic;
}
.login .msg {
  text-align: center;
  margin-bottom: 10px;
  color: #000;
}
// .login .form input {
//   margin-bottom: 20px;
// }
.login .form input.input-text {
  border: none;
  background: none;
  width: 100%;
  color: #000;
  font-size: 1em;
  outline: none;
  border-radius: 20px;
  border: 1px solid #4c4754;
  padding: 4px 11px;
}
.eye-icon {
  position: absolute;
  top: 30%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000;
  font-size: 16px;
  transition: color 0.3s ease;
}

.eye-icon:hover {
  color: #1890ff;
}
.input-text::placeholder {
  color: #000;
}
.input-text::-webkit-input-placeholder {
  color: #000;
}
.input-text:-moz-placeholder {
  color: #000;
  opacity: 1;
}
.input-text::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.input-text:-ms-input-placeholder {
  color: #000;
}
.input-text::-ms-input-placeholder {
  color: #000;
}
.login .form .btn-login {
  background: none;
  text-decoration: none;
  background-color: $secondary;
  border-color: $secondary;
  border-radius: 20px;
  color: $white;
  padding: 5px 2em;
  transition: 0.5s;
}
.login .form .btn-login:hover {
  background: $theme-black;
  transition: 0.5s;
  color: #fff;
}


.login-form {
  max-width: 300px;
  margin: auto;
}
.login-form-input{
  border-radius: 8px;
  margin-top: 10px;
  height: 40px;
  font-size: 15px;
}
.login-form-forgot, .login-form-forgot:hover {
  float: right;
  color: $secondary;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  // width: 100%;
  border-radius: 8px;
  background-color: $secondary;
  color: #fff;
  // height: 35px;
}
.login-form-button:hover, .login-form-button:focus, .login-form-button:active  {
  // width: 100%;
  border-radius: 8px;
  background-color: $secondary;
  color: #fff;
}
.login-footer{
  margin: 20px auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}



.login .forgot {
  text-decoration: none;
  color: #337ab7;
}
.login .forgot:hover,
.login .forgot:focus {
  text-decoration: underline;
}
.login-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  .btn-forgot {
    color: $secondary;
  }
}
.login-error {
  color: #f00;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}
.logout-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  h6 {
    text-align: center;
  }
}
.logout-logo {
  text-align: center;
}
.login-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  a {
    margin-left: 5px;
    color: #32bae2 !important;
  }
}

/*=========responsive section=========*/

@media (max-width: 767px) {
  .loginpage-wrapper {
    width: 90%;
  }
  .login-image {
    display: none;
  }
  .login-box {
    min-width: 100%;
    max-width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .login .logo {
    width: 200px;
  }

}

/*login page ends*/

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11000;
  background-color: rgb(0,0,0,0.5);
  }
  .loaderImage {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }
  