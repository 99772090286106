.site-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
    margin: 0px auto;
    padding: 30px 10px 10px 10px;
    img {
        width: 200px;
        margin-bottom: 30px;
    }
    h2 {
        font-size: 20px;
        //color: $theme-red;
        margin-bottom: 0;
    }
}