.verify-email-block{
    width: 100%;
    padding: 0 10px 10px 10px;
    h2 {
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 0;
        padding: 0 20px;
        text-align: center;
        color: #c64339;
    }
    h4{
        font-size: 18px;
        font-weight: 400;
        padding: 20px;
        text-align: center;
        max-width: 680px;
        margin: auto;
    }
}