@import "../../../../src/scss/variables.scss";

.user-manage {
  width: 100%;
  .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 15px 30px;
    border: 0px;
    transition: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: $primary-blue;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    .ant-tabs-tab-btn {
      color: $white;
      font-weight: 400;
    }
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-bottom
    > .ant-tabs-nav
    .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-bottom
    > div
    > .ant-tabs-nav
    .ant-tabs-tab:not(:last-of-type) {
    margin-right: 0px;
  }
  .ant-tabs-content-holder {
    background-color: $white;
  }
  &__content {
    &--sorter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $primary-blue;
      padding: 10px;
      .sorter-left {
        color: $white;
      }
      .sorter-right {
        display: flex;
        flex-wrap: wrap;
        .select-drop {
          .ant-select {
            margin-right: 5px;
            .ant-select-arrow {
              color: #d9d9d9;
            }
            .ant-select-selection-item {
              color: #030303;
            }
          }
          .ant-select-focused .ant-select-selector,
          .ant-select-selector:focus,
          .ant-select-selector:active,
          .ant-select-open .ant-select-selector {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
          }
          .ant-select-selector {
            border-color: #d9d9d9;
            background: transparent;
            border-radius: 10px !important;

            &:hover,
            &:focus {
              border-color: #40a9ff;
              border-right-width: 1px !important;
            }
          }
        }

        .sort-by__title {
          color: $white;
          margin-right: 5px;
        }
        .asc-dsc {
        }
      }
    }
    .user-manage__edit,
    .user-manage__delete {
      max-width: 24px;
      display: inline-block;
      img {
        width: 100%;
      }
    }
    .ant-table-thead {
      tr {
        th {
          background: #e9e9ea;
          color: #383636;
        }
      }
    }
    .ant-table-pagination {
      justify-content: center !important;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-item {
      border: 0px;
    }
    .ant-pagination-item-active {
      color: $primary-blue;
    }
    .disabled-row {
      background-color: #dcdcdc;
      pointer-events: none;
    }
  }
}
