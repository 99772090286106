@import "../../../src/scss/variables";

.pstn-popover {
    width: 50vh !important;
    &__title {
        color: $dark-blue;
        font-size: 12px;
        margin-bottom: 10px;
    }
    &__dropdown {
        .ant-select {
            width: 100%;
            margin-bottom: 10px;
            &-selector {
                border-radius: 10px !important;
            }
        }
    }
    &__input {
        margin-bottom: 10px;
        .ant-input {
            border-radius: 10px;
        }
    }
    &__send--btn {
        .ant-btn {
            width: 100%;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            background-color: $dark-blue;
            color: $white;
        }
    }
}