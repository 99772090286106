@import "../../../src/scss/variables.scss";

.form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &__content {
        box-shadow: 0 0 10px #c4bdbd;
        border-radius: 8px;
        margin: auto 20px;
        @media (min-width: 576px) { 
            min-width: 430px;
        }
        &.width-changer {
            @media (min-width: 768px) { 
                min-width: 680px;
            }
        }
        .logo-wrapper {
            width: 200px;
            margin: auto;
            margin-top: 30px;
        }
    }
}

.signup-form {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 30px 30px 30px;
    .create-account {
        background-color: $theme-red;
        border-color: $theme-red;
        border-radius: 20px;
        &:focus,
        &:hover {
            background-color: $theme-black;
            border-color: $theme-black;
        }
    }
    .custom-row {
        .ant-form-item-control-input-content {
            padding: 0 5px;
        }
    }
    .forgot-btn {
        color: $secondary;
    }
    .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child,.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-color: $border-ash !important;
    }
    .ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid $border-ash;
    }
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 20px;
        border-color: $border-ash; 
    }
}

.form-h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 20px 30px;
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
}
.forgot-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}