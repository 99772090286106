@import "../../src/scss/variables";
#root,
.project-wrapper {
  height: 100%;
  background-color: $white;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.ant-modal-close {
  &:focus {
    outline: none;
  }
}

#userManageModal,
#userManageTeamModal,
#userManageAgentModal,
#botsManagerModal,
#cannedAddModal,
#cannedEditModal #meetAlertModal {
  .ant-modal-body {
    border-radius: 0 0 20px 20px;
    background-color: white; //$theme-blue;
    padding: 0px 2px 15px 2px;
  }
  .ant-modal-header {
    background-color: $theme-blue;
    border-bottom: 0px;
    text-align: left;
    .ant-modal-title {
      color: $white;
      font-weight: 400;
    }
  }
  .dept-fields {
    background: $white;
    padding: 15px 20px;
    .ant-input {
      border-radius: 10px;
      margin-bottom: 15px;
    }
    .ant-select {
      width: 100%;
      margin-right: 0px;
      border-color: #d9d9d9 !important;
      margin-bottom: 15px;
    }
  }
  .save-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    padding-right: 20px;
    button {
      background: $dark-blue;
      border-radius: 15px;
      color: $white;
    }
  }
  .select-drop {
    width: 100%;
    .ant-select {
      width: 100% !important;
      margin-right: 5px;
      .ant-select-arrow {
        color: #d9d9d9;
      }
      .ant-select-selection-item {
        color: #030303;
      }
    }
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
    }
    .ant-select-selector {
      border-color: #d9d9d9;
      background: transparent;
      border-radius: 10px !important;

      &:hover,
      &:focus {
        border-color: #40a9ff;
        border-right-width: 1px !important;
      }
    }
  }
}

#userManageModal,
#userManageTeamModal,
#userManageAgentModal,
#botsManagerModal,
#reminderModal,
#chatModal,
#audioModal,
#videoModal,
#agentReminderTabModal,
#offlineReplyModal,
#cannedAddModal,
#cannedEditModal,
#meetAlertModal {
  .ant-modal-wrap {
    display: flex;
    align-items: center;
    .ant-modal {
      top: 0px;
    }
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
    text-align: center;
  }
  .ant-modal-content {
    border-radius: 20px;
    .ant-modal-close {
      position: absolute;
      right: -30px;
      top: 2px;
      border: 1px solid #000;
      padding: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      .ant-modal-close-x {
        width: 20px;
        height: 20px;
        line-height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}

.ant-switch-checked {
  background-color: $theme-red;
}

.ant-modal-title {
  color: $white;
}

.antd-table-wrap {
  overflow: auto;
}

.comma:not(:first-child) {
  margin-left: -0.3em;
}

.comma:first-child:empty ~ .comma:not(:empty) {
  margin-left: 0;
}

.comma:first-child:empty ~ .comma:not(:empty) ~ .comma:not(:empty) {
  margin-left: -0.3em;
}

.comma:empty {
  display: none;
}

.comma:not(:first-child):before {
  content: "  , ";
  padding-left: 3px;
  padding-right: 3px;
}

.comma:empty + .comma:not(:empty):before {
  content: "";
}

.comma:not(:empty) ~ .comma:empty + .comma:not(:empty):before {
  content: "  , ";
  padding-left: 3px;
  padding-right: 3px;
}

#codeSnippetModal {
  .ant-modal {
    width: 80% !important;
  }
  .ant-modal-title {
    color: #fff;
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
    background-color: #c64339;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-body {
    background-color: $white;
    color: $white;
  }
  // code {
  //   color: $white;
  // }
}

.ant-spin {
  .ant-spin-dot-item {
    background-color: $secondary;
  }
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: fit-content;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.75);
}
.non-editable {
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.common-button {
  background-color: $secondary;
  border-color: $secondary;
  border-radius: 20px;
  color: $white;
  &:hover,
  &:focus {
    background-color: $theme-black;
    border-color: $theme-black;
  }
}

.common-modal {
  .ant-modal-body {
    border-radius: 20px 20px 20px 20px;
    background-color: $theme-blue;
    padding: 0px 2px 15px 2px;
  }
  .ant-modal-header {
    background-color: $theme-blue;
    border-bottom: 0px;
    text-align: left;
    .ant-modal-title {
      color: $white;
      font-weight: 400;
    }
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .dept-fields {
    background: $white;
    padding: 15px 20px;
    .ant-input {
      border-radius: 10px;
      margin-bottom: 15px;
    }
    .ant-select {
      width: 100%;
      margin-right: 0px;
      border-color: #d9d9d9 !important;
      margin-bottom: 15px;
    }
    .ant-select-selector {
      border-radius: 15px;
    }
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-input {
    margin-bottom: 16px;
  }
  .ant-modal-close {
    position: absolute;
    right: -30px;
    top: 2px;
    border: 1px solid #000;
    padding: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    .ant-modal-close-x {
      width: 20px;
      height: 20px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .save-btn-wrap {
    display: flex;
    justify-content: flex-end;
    padding: 15px 15px 0 15px;
    .cancel {
      color: $theme-black;
      margin-left: 15px;
      border-radius: 15px;
      background: $white;
      border-color: $white;
      &:hover,
      &:focus {
        color: $theme-black;
        background-color: $white;
        border-color: $white;
      }
    }
    .save {
      color: $white;
      margin-left: 15px;
      border-radius: 15px;
      background: $theme-black;
      border-color: $theme-black;
      &:hover,
      &:focus {
        background-color: $theme-black;
        border-color: $theme-black;
      }
    }
    .hint {
      background: transparent;
      border-color: transparent;
      color: $white;
    }
    .ant-modal-title {
      color: #000;
    }
    .ant-modal-header {
      border-radius: 20px 20px 0 0;
    }
    .ant-modal-content {
      border-radius: 20px;
    }
  }
}

// form style
.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-col-24.ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item-label > label {
  height: 25px !important;
  margin-bottom: 10px;
}
.ant-form-item-control-input-content {
  // height: 33px!important;
}

// scrollbar style
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

// react-phone-input css
.PhoneInput {
  border: 1px solid #d7c8c8 !important;
  border-radius: 8px !important;
  padding: 2px 10px !important;
}

.PhoneInput--focus {
  border: 1px solid #d7c8c8 !important;
  border-radius: 8px !important;
  padding: 2px 10px !important;
}

input[type="tel" i] input[type="tel" i]:focus {
  border: none !important;
}
input[type="tel" i] {
  border: none !important;
}
textarea:focus,
input:focus {
  outline: none;
}

.custom-react-phone-input {
  width: 100% !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
  .form-control {
    height: 30px !important;
    width: 287px !important;
  }
}
