// <div class='icon-container'>
//   <img src="https://cdn2.iconfinder.com/data/icons/flatfaces-everyday-people-square/128/beard_male_man_face_avatar-512.png" />
//   <div class='status-circle'>
//   </div>
// </div>

.icon-container {
  width: 35px;
  height: 35px;
  min-height: max-content;
  position: relative;
}

.icon-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-position: bottom;
}

.status-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  //   background-color: grey;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
  animation: blink 1s linear infinite;
}

.blink {
  display: block;
  width: 15px;
  height: 15px;
  background-color: red;
  opacity: 0.7;
  border-radius: 50%;
  animation: blink 1s linear infinite;
}

//    <div class="online-indicator">
//     <span class="blink"></span>
//   </div>

div.online-indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: red;
  border-radius: 50%;
  position: relative;
}
span.blink {
  display: block;
  width: 15px;
  height: 15px;
  background-color: red;
  opacity: 0.7;
  border-radius: 50%;
  animation: blink 1s linear infinite;
}
